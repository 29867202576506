import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loader from '../src/Views/Loader/Loader';
const LoginPage = React.lazy(() => import('./Views/Login/Login'));
const ResetPassword = React.lazy(() => import('./Views/ResetPassword/ResetPassword'));
const ForgotPassword = React.lazy(() => import('./Views/ForgotPassword/ForgotPassword'));
const loading = () => <Loader></Loader>;
const DefaultLayout = React.lazy(() => import('./Views/DefaultLayout'));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div>
        <React.Suspense fallback={loading()} >
          <Switch>
            <Route exact path="/" name="Login Page" render={props => <LoginPage {...props} />} />
            <Route exact path="/reset-password" name="Reset Password" render={props => <ResetPassword {...props} />} />
            <Route exact path="/forgot-password/:token" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
            <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </div>
    );
  }
}

export default App;
